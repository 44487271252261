section.login {
    background: #f4f8ff;
    padding: 150px 0;
    h1 {
        color: #24374e;
        font-size: 32px;
    }
    input {
        display: block;
        border: 1px solid #deeaf1;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        resize: none;
        font-family: inherit;
        padding: 3px 11px 4px;
        width: 100%;
        max-width: 400px;
    }
    button {
        display: block;
        border: none;
        cursor: pointer;
        background: #0089ff;
        border-radius: 3px;
        overflow: hidden;
        cursor: pointer;
        text-align: center;
        padding: 9px 0 11px;
        display: block;
        width: 144px;
        color: #fff;
        font-size: 13px;
    }
    label {
        display: block;
        color: #00aeef;
        padding: 0 0 7px;
    }
}

.login-item {
    padding: 5px 0;
}