// Line heights
//-------------------------------------------------------
body {
    color: #626262;
    width: 100%;
    line-height: 1.35;
    @include ff-text;
    background: #fff;
    position: relative;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
    // sticky footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: 15px;
}

// Headers
//-----------------------------------------------------
$headers-list: 'h1',
'h2',
'h3',
'h4',
'h5',
'h6';
#{$headers-list} {
    color: inherit;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    @include ff-header;
    font-weight: $font-weight-semibold;
}

// Links
//-----------------------------------------------------
a {
    color: inherit;
    text-decoration: none;
}

p,
ul,
ol {
    margin-bottom: 12px;
    line-height: 1.8;
    &+h1,
    &+h2,
    &+h3,
    &+h4,
    &+h5,
    &+h6 {
        margin-top: 15px;
    }
}

// Lists
//-----------------------------------------------------
ul,
ol {
    margin: 0 0 15px 0;
    padding: 0 0 0 1.1em;
    li {
        margin-bottom: 10px;
    }
}

.wrapper {
    width: 100%;
    max-width: 1180px;
    padding: 0 20px;
    margin: 0 auto;
    @media(max-width: 500px) {
        padding: 0 15px;
    }
}