//  Fonts
//-------------------------------------------------------
@mixin ff-header {
    font-family: 'Poppins', serif;
}

@mixin ff-text {
    font-family: 'Poppins', sans-serif;
}

// Weights
//-------------------------------------------------------
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;