body {
    &.blured {
        section,
        footer {
            -webkit-filter: blur(5px);
            -moz-filter: blur(5px);
            filter: blur(5px);
        }
    }
}

header {
    padding: 27px 0 0;
    height: 95px;
    @media(max-width: 500px) {
        padding: 10px 0;
        height: auto;
        border-bottom: 1px solid #f1f1f1;
    }
}

.logo-header {
    position: absolute;
    @media(max-width: 500px) {
        position: relative;
    }
    a {
        background-image: url(../../images/logo.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: block;
        width: 167px;
        height: 54px;
        @media(max-width: 500px) {
            margin: 0;
        }
    }
}

.mobile-menu {
    display: none;
    @media(max-width: 500px) {
        display: block;
        position: absolute;
        top: 20px;
        right: 15px;
        width: 23px;
        height: 28px;
        background-image: url(../../images/icons.png);
        background-size: auto 100%;
        background-position-x: -56px;
        cursor: pointer;
        transition: all .5s;
        &.active {
            background-position-x: -82px;
        }
    }
}

nav {
    text-align: right;
    padding: 9px 0 0;
    @media(max-width: 500px) {
        padding: 0 12px 10px;
        text-align: left;
        transition: .5s ease-in-out;
        overflow: hidden;
        position: absolute;
        background:#f0f7ff;
        width: 100%;
        left: 0;
        top: 74px;
        z-index: 1;
        &:not(.active) {
            display: none;
        }
    }
    a {
        font-size: 14px;
        color: #0e1821;
        margin-left: 32px;
        @media(max-width: 700px) {
            font-size: 13px;
            margin: 0 6px;
        }
        @media(max-width: 500px) {
            font-size: 15px;
            margin: 0;
            display: block;
            padding: 15px 0 18px;
            border-bottom: 1px solid #cfdbe9;
            &:last-child {
                border-bottom: unset;
            }
        }
        span {
            font-size: 10px;
            color: #fff;
            background: #00aeef;
            margin-left: 5px;
            padding: 1px 4px 1px;
            border-radius: 4px;
            overflow: hidden;
            @media(max-width: 600px) {
                display: none;
            }
        }
    }
}