section.top {
    background-image: url(../../images/top.png);
    background-repeat: no-repeat;
    background-position: 101% top;
    background-size: 45% auto;
    min-height: 550px;
    margin: 15px 0 0;
    @media(max-width: 1600px) {
        background-position: 100% top;
        background-size: 50% auto;
    }
    @media(max-width: 1200px) {
        background-position: 100% top;
        background-size: 55% auto;
        min-height: 480px;
    }
    @media(max-width: 1100px) {
        background-size: 50% auto;
    }
    @media(max-width: 1000px) {
        background-position: 100% 40px;
        min-height: unset;
    }
    @media(max-width: 900px) {
        background-position: 120% 40px;
    }
    @media(max-width: 500px) {
        background-size: 97% auto;
        margin: 10px 0 0;
        background-position: right bottom;
        padding: 0 0 290px;
    }
    @media(max-width: 450px) {
        padding: 0 0 250px;
    }
    @media(max-width: 400px) {
        margin: 0;
        padding: 0 0 215px;
        background-position: right bottom;
    }
    @media(max-width: 350px) {
        padding: 0 0 180px;
        background-position: right bottom;
    }
    article {
        width: 50%;
        padding: 90px 0 50px 0;
        max-width: 490px;
        @media(max-width: 900px) {
            padding: 40px 0 50px 0;
            max-width: 550px;
            width: 60%;
        }
        @media(max-width: 600px) {
            padding: 0 0 30px;
        }
        @media(max-width: 500px) {
            padding: 20px 0;
            width: 100%;
        }
    }
    h1 {
        font-size: 39px;
        color: #0e1821;
        padding: 0 0 10px;
        @media(max-width: 600px) {
            font-size: 26px;
        }
        span {
            display: block;
        }
        .colored {
            color: #00aeef;
        }
    }
}

.partners-list {
    display: table;
    width: 100%;
    padding: 40px 0 35px;
    @media(max-width: 900px) {
        width: 900px;
    }
    @media(max-width: 500px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }
    &>* {
        display: table-cell;
        vertical-align: top;
        @media(max-width: 500px) {
            display: block;
            width: 33.33%;
            text-align: center;
            img {
                max-width: 90%;
            }
        }
    }
}

.partners-list-wrapper {
    @media(max-width: 900px) {
        width: 100%;
        overflow: scroll;
    }
}

section.partners {
    background-image: url(../../images/bg1.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center bottom;
    padding: 0 0 210px;
    @media(max-width: 600px) {
        padding: 40px 0 70px;
    }
    @media(max-width: 500px) {
        padding: 40px 0 80px;
    }
    @media(max-width: 500px) {
        background: none;
        padding: 40px 0 40px;
    }
    h2 {
        color: #00aeef;
        font-size: 28px;
        padding: 0 0 13px;
    }
    p {
        max-width: 930px;
        margin: 0 auto 12px;
    }
}

.partners-table {
    display: table;
    width: 100%;
    padding: 30px 0 0;
    @media(max-width: 600px) {
        display: block;
    };
    @media(max-width: 500px) {
        padding: 10px 0 0;
    }
    &>* {
        display: table-cell;
        vertical-align: top;
        @media(max-width: 600px) {
            display: block;
        }
    }
}

.pt-image {
    width: auto;
    padding: 0 30px 0 40px;
    @media(max-width: 1200px) {
        padding: 0 40px 0 0;
    }
    @media(max-width: 800px) {
        padding: 0 30px 30px 0;
    }
    @media(max-width: 500px) {
        display: none;
    }
    img {
        display: block;
        width: 100%;
        max-width: 410px;
        @media(max-width: 800px) {
            max-width: 320px;
            margin: 0 auto;
        }
    }
}

.pt-info {
    width: 55%;
    padding: 25px 40px 0 20px;
    @media(max-width: 1000px) {
        padding: 25px 0 0;
    }
    @media(max-width:800px) {
        margin: 0 auto;
    }
    @media(max-width:600px) {
        width: 100% !important;
    }
}

section.experience {
    background-color: #0e1821;
    background-image: url(../../images/expirience.png);
    background-repeat: no-repeat;
    background-size: 54% auto;
    background-position: right top;
    padding: 70px 0 60px;
    @media(max-width: 500px) {
        padding: 40px 0 30px;
        background-image: unset;
    }
    h2 {
        font-size: 32px;
        color: #fff;
        text-align: center;
        @media(max-width: 600px) {
            font-size: 26px;
        }
    }
}

.experience-items {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0 0;
}

.ei-item {
    width: 48%;
    background: #fff;
    margin: 1%;
    position: relative;
    padding: 33px 33px 33px 125px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
    transform: translate3d(0px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    transform-style: preserve-3d;
    opacity: 0.2;
    transition: all 1s ease 0s;
    &.active {
        transform: translate3d(0px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
        opacity: 1;
    }
    @media(max-width: 800px) {
        padding: 25px 25px 15px 100px;
    }
    @media(max-width: 600px) {
        padding: 25px 25px 20px 100px;
        margin: 0 0 15px;
        width: 100%;
    }
    img {
        width: 58px;
        display: block;
        position: absolute;
        top: 32px;
        left: 35px;
        @media(max-width: 800px) {
            width: 45px;
            top: 24px;
            left: 28px;
        }
    }
    h3 {
        font-size: 18px;
        @include ff-text;
        font-weight: $font-weight-semibold;
        color: #414142;
        padding: 0 0 8px;
    }
}

section.obsessed {
    background-image: url(../../images/bg2.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    padding: 220px 0 60px;
    @media(max-width: 800px) {
        padding: 120px 0 60px;
    }
    @media(max-width: 500px) {
        background: none;
        padding: 20px 0 35px;
    }
    h2 {
        font-size: 28px;
        color: #00aeef;
        padding: 0 0 15px;
    }
}

.absessed-table {
    display: table;
    width: 100%;
    @media(max-width: 800px) {
        display: block;
    }
    &>* {
        display: table-cell;
        width: 50%;
        vertical-align: top;
        @media(max-width: 1200px) {
            width: auto;
        }
        @media(max-width: 800px) {
            display: block;
            width: 100%;
        }
    }
}

.at-image {
    padding: 0 40px 0 30px;
    @media(max-width: 1200px) {
        padding: 0 40px 0 0;
    }
    @media(max-width: 800px) {
        padding: 0 0 30px;
    }
    @media(max-width: 500px) {
        display: none;
    }
    img {
        display: block;
        max-width: 100%;
        @media(max-width: 800px) {
            width: 320px;
            margin: 0 auto;
        }
    }
}

.at-info {
    padding: 25px 0 0 10px;
    @media(max-width: 1200px) {
        width: 570px !important;
    }
    @media(max-width: 1000px) {
        padding: 25px 0 0;
    }
    @media(max-width:800px) {
        margin: 0 auto;
    }
    @media(max-width:600px) {
        width: 100% !important;
    }
}

.round-items {
    padding: 25px 0 0;
    @media(max-width: 800px) {
        display: flex;
        flex-wrap: wrap;
        max-width: 300px;
        margin: 0 auto;
    }
    &:after {
        content: '';
        clear: both;
        display: block;
        height: 0;
    }
    article {
        background: #e0f7ff;
        width: 130px;
        height: 130px;
        float: left;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        padding: 20px 0 0;
        @media(max-width: 1000px) {
            width: 105px;
            height: 105px;
            padding: 10px 0 0;
        }
        @media(max-width: 800px) {
            height: 130px;
            width: 130px;
            padding: 130px 0 0;
            position: relative;
            margin: 0 7px 14px !important;
        }
        &:nth-child(-n+3) {
            margin-right: 12px;
            @media(max-width: 1000px) {
                margin-right: 7px;
            }
        }
    }
    h3 {
        font-size: 47px;
        color: #0c2841;
        text-transform: uppercase;
        @media(max-width: 1000px) {
            font-size: 37px;
        }
        @media(max-width: 800px) {
            position: absolute;
            top: 20px;
            text-align: center;
            width: 100%;
        }
    }
    p {
        font-size: 13px;
        max-width: 91px;
        margin: 0 auto;
        line-height: 1.3;
        color: #0c2841;
        @media(max-width: 1000px) {
            font-size: 12px;
        }
        @media(max-width: 800px) {
            font-size: 13px;
            position: absolute;
            top: 68px;
            display: block;
            width: 100%;
            max-width: unset;
        }
    }
}

section.team {
    padding: 100px 0 0;
    @media(max-width: 800px) {
        padding: 50px 0 0;
    }
    @media(max-width:500px) {
        // background: #effbff;
        padding: 10px 0 0;
    }
}

.team-table {
    display: table;
    width: 100%;
    @media(max-width: 800px) {
        display: flex;
        flex-wrap: wrap-reverse;
    }
    &>* {
        display: table-cell;
        vertical-align: top;
        width: 50%;
        @media(max-width: 800px) {
            display: block;
            width: 100%;
        }
    }
}

.tt-info {
    padding: 40px 0 0 30px;
    @media(max-width: 800px) {
        padding: 20px 0 0;
        max-width: 570px;
        margin: 0 auto;
        .round-items {
            max-width: 570px;
            text-align: center;
            display: block;
            article {
                display: inline-block !important;
                float: unset;
            }
        }
    }
    @media(max-width: 500px) {
        .round-items {
            max-width: 100%;
            article {
                height: 107px;
                width: 107px;
                padding: 107px 0 0;
                margin: 0 5px 0 0 !important;
                &:last-child {
                    margin: 0 0 0 0 !important;
                }
            }
            h3 {
                top: 15px;
            }
            p {
                font-size: 12px;
                top: 56px;
            }
        }
    }
    @media(max-width: 500px) {
        .round-items {
            padding: 0 0 35px;
            article {
                height: 93px;
                width: 93px;
                padding: 93px 0 0;
                // background: #fff;
            }
            h3 {
                top: 10px;
            }
            p {
                font-size: 11px;
                top: 51px;
            }
        }
    }
    @media(max-width: 330px) {
        .round-items {
            article {
                height: 90px;
                width: 90px;
                padding: 90px 0 0;
            }
        }
    }
    h2 {
        font-size: 27px;
        color: #00aeef;
        padding: 0 0 15px;
    }
}

.tt-image {
    padding: 0 0 0 10px;
    @media(max-width: 800px) {
        padding: 20px 0 40px;
    }
    @media(max-width: 500px) {
        display: none;
    }
    img {
        display: block;
        max-width: 100%;
        @media(max-width: 800px) {
            width: 320px;
            margin: 0 auto;
        }
    }
}

section.carbon {
    padding: 0 0 220px;
    background-image: url(../../images/bg3.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom center;
    @media(max-width: 600px) {
        padding: 0 0 100px;
    }
    @media(max-width:500px) {
        display: none;
    }
}

.carbon-table {
    display: table;
    width: 100%;
    @media(max-width: 800px) {
        display: block;
    }
    &>* {
        display: table-cell;
        vertical-align: top;
        width: 50%;
        @media(max-width: 800px) {
            display: block;
            width: 100%;
        }
    }
}

.ct-image {
    padding: 0 120px 0 70px;
    @media(max-width: 1000px) {
        padding: 0 60px 0 30px;
    }
    @media(max-width:800px) {
        padding: 0;
    }
    @media(max-width:500px) {
        display: none;
    }
    img {
        display: block;
        max-width: 100%;
        @media(max-width: 800px) {
            width: 320px;
            margin: 0 auto;
        }
    }
}

.ct-info {
    padding: 20px 0 0;
    @media(max-width: 800px) {
        padding: 40px 0 0;
        max-width: 570px;
        margin: 0 auto;
    }
    h2 {
        font-size: 27px;
        color: #00aeef;
        padding: 0 0 15px;
    }
    a {
        color: #00aeef;
    }
}

section.work {
    background: #effbff;
    padding: 60px 0 90px;
}

.work-header {
    padding: 0 0 30px;
    h2 {
        color: #00aeef;
        font-size: 32px;
        text-align: center;
        padding: 0 0 15px;
    }
    p {
        text-align: center;
        max-width: 900px;
        margin: 0 auto;
    }
}

.work-table {
    display: table;
    width: 100%;
    background: #fff;
    @media(max-width: 1000px) {
        display: block;
    }
    &>* {
        display: table-cell;
        vertical-align: top;
        @media(max-width: 1000px) {
            display: block;
        }
    }
}

.wt-info {
    width: auto;
    padding: 50px 30px 80px 40px;
    @media(max-width: 1000px) {
        padding: 40px 30px 0;
    }
    h3 {
        font-size: 25px;
        color: #755ca5;
        padding: 0 0 15px;
    }
}

.wt-slider {
    width: 750px;
    @media(max-width: 1200px) {
        width: 720px;
    }
    @media(max-width: 1000px) {
        width: 100%;
        margin: 0 auto;
        padding: 0 10px 30px;
        max-width: 720px;
    }
}

.nojob {
    background-image: url(../../images/nojob.jpg);
    content: '';
    height: 310px;
    background-repeat: no-repeat;
    background-size: 90% auto;
    margin: 40px 30px 0 0;
    padding: 100px 0 0;
    @media(max-width: 700px) {
        height: 280px;
        margin: 40px 0 0;
    }
    @media(max-width: 500px) {
        background-image: url(../../images/nojob-mob.jpg);
        height: 370px;
    }
    @media(max-width: 400px) {
        height: 300px;
    }
    @media(max-width: 350px) {
        height: 270px;
    }
}
.nojob-text {
    background: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;
    max-width: 360px;
    border-radius: 80px;
    margin: 0 auto;
    box-shadow: 0 0 16px 0
    rgba(0, 0, 0, 0.05);
    font-size: 18px;
    color: #00aeef;
    @media(max-width: 500px) {
        font-size: 12px;
        padding: 15px;
        max-width: 270px;
    }
}

.wt-slider-wrapper {
    padding: 40px 30px 0 30px;
    width: 720px;
    height: 300px;
    overflow: hidden;
    position: relative;
    @media(max-width: 1200px) {
        width: 690px;
    }
    @media(max-width: 1000px) {
        width: 100%;
    }
    @media(max-width: 400px) {
        padding: 40px 10px 0;
    }
}

.wt-slides {
    &:after {
        content: '';
        clear: both;
        display: block;
        height: 0;
    }
}

.wt-item {
    float: left;
    width: 33.33%;
    display: block;
    a {
        display: block;
        border: 1px solid #deeaf1;
        overflow: hidden;
        border-radius: 4px;
        margin: 0 10px;
        padding: 14px 0 25px;
    }
    img {
        max-width: 140px;
        margin: 0 auto;
        display: block;
    }
    h4 {
        color: #00aeef;
        font-size: 14px;
        @include ff-text;
        text-align: center;
        padding: 0 0 8px;
        min-height: 39px;
        max-width: 150px;
        margin: 0 auto;
    }
    span {
        color: #fff;
        font-size: 11px;
        background-color: #00aeef;
        font-weight: $font-weight-medium;
        display: block;
        padding: 6px 0 9px;
        text-align: center;
        width: 117px;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 auto;
    }
}

.wt-img {
    height: 150px;
    padding: 0 0 15px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
}

.wt-prev {
    background: no-repeat center center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAYCAMAAAAWLodmAAAARVBMVEUAAAAvgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNoscSSbAAAAFnRSTlMAVPK6fUoT9+ni2cOxo5eKcWQ+Mikef1QvTwAAAF9JREFUGNNl0FkOgCAMBFBBEPfd3v+oDklrTaZ/LyFMO41PG9MPIvJhBibDAoydYgUGwwb0jyIB8VbsQLkUByCnIlRkRa4IFlQcmKiil/SLJ1A6b+Zb00V0rTXBLXmDL2CMCDRffkx5AAAAAElFTkSuQmCC');
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 150px;
    left: 0;
    &.disabled {
        display: none;
    }
    @media(max-width:500px) {
        display: none;
    }
}

.wt-next {
    background: no-repeat center center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAYCAMAAAAWLodmAAAARVBMVEUAAAAvgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNovgNoscSSbAAAAFnRSTlMAVPK6fUoT9+ni2cOxo5eKcWQ+Mikef1QvTwAAAFxJREFUGNNl0FkOwCAIBFC1Wrvv5f5HLf7MlMDfSwjLhJy6wBKRH2flAvWTciVH5Qa+g3IHn6TM4F2VB3ip5ARLYwRjYzGsRsl3corfwO28zF/Nj/y3TMKnZBP8AGXgCDT440+7AAAAAElFTkSuQmCC');
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 150px;
    right: 0;
    &.disabled {
        display: none;
    }
    @media(max-width:500px) {
        display: none;
    }
}

section.touch {
    padding: 80px 0 85px;
    text-align: center;
    @media(max-width: 800px) {
        text-align: left;
    }
    h2 {
        color: #0e1821;
        font-size: 32px;
        padding: 0 0 17px;
        @media(max-width: 400px) {
            text-align: center;
        }
    }
    p {
        max-width: 1000px;
        margin: 0 auto 40px;
        @media(max-width: 400px) {
            text-align: center;
        }
    }
}

.touch-items {
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 500px) {
        text-align: left;
    }
    @media(max-width: 1000px) {
        max-width: 800px;
        margin: 0 auto;
    }
    &.limited-width {
        max-width: 800px;
        margin: 0 auto;
    }
    .btn-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @media(max-width: 400px) {
            position: unset;
            bottom: unset;
            left: unset;
            right: unset;
        }
    }
}
.ti-item {
    width: 33.3%;
    padding: 0 10px 28px;
    position: relative;
    margin: 0 auto 50px;
    @media(max-width: 800px) {
        width: 50%;
        padding: 0 0 28px 0;
    }
    @media(max-width: 400px) {
        width: 100%;
        padding: 25px 18px !important;
        border: 1px solid #e9f0f3;
        border-radius: 12px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto 15px;
    }

    img {
        display: block;
        margin: 0 auto 15px;
        max-height: 25px;
        @media(max-width: 800px) {
            margin: 0 0 15px;
        }
        @media(max-width: 500px) {
            max-width: 100%;
        }
        @media(max-width: 400px) {
            margin: 0 auto 15px;
        }
    }
    p {
        margin: 0 auto 6px !important;
        font-size: 13px;
        &:last-of-type {
            margin: 0 auto 20px !important;
        }
        a {
            color: #00aeef;
        }
    }
    .btn {
        color: #fff;
        font-size: 12px;
        background-color: #1b242c;
        display: block;
        padding: 5px 20px 7px 12px;
        text-align: center;
        width: 116px;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        @media(max-width: 800px) {
            margin: 0;
        }
        @media(max-width: 400px) {
            margin: 0 auto;
            width: 126px;
            padding: 5px 20px 7px 12px;
        }
        &:after {
            position: absolute;
            display: block;
            content: '';
            background-image: url(../../images/plane.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            width: 10px;
            height: 8px;
            top: 10px;
            right: 8px;
            @media(max-width: 400px) {
                right: 15px;
            }
        }
    }
}
.about {
    background-color: #0e1821;
    position: relative;
    &:before {
        content: '';
        display: block;
        background-image: url(../../images/bg3.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center bottom;
        background-color: #fff;
        padding: 104px 0 0;
        @media(max-width: 500px) {
            display: none;
        }
    }
    &:after {
        content: '';
        display: block;
        background-image: url(../../images/bg4.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center top;
        background-color: #fff;
        padding: 108px 0 0;
        @media(max-width: 500px) {
            display: none;
        }
    }
}
.about-info {
    color: #fff;
    text-align: center;
    margin: 40px 0 40px;
    h2 {
        font-size: 27px;
        padding: 0 0 15px;
    }
    p {
        display: block;
        max-width: 1000px;
        margin: 0 auto;
    }
}
.about-sections {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 40px;
    max-width: 1110px;
}
.about-section {
    width: 47%;
    width: calc(50% - 30px);
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 15px;
    padding: 100px 40px 30px;
    position: relative;
    @media(max-width: 800px) {
        width: 100%;
        margin: 0 0 20px;
    }
    @media(max-width: 600px) {
        padding: 100px 18px 9px;
    }
    img {
        position: absolute;
        max-width: 117px;
        max-height: 59px;
        left: 40px;
        top: 30px;
        @media(max-width: 600px) {
            left: 18px;
        }
    }
}
.about-section:last-of-type {
    img {
        top: 20px;
    }
}