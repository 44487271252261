@charset 'utf-8';
// Mixins and vars
@import "../common/utils/reset";
@import "../common/utils/moving";
// Typography
@import "../common/typography/fonts";
@import "../common/typography/typography";
//Vendor
@import "../common/vendor/glider";
// Partials
@import "partials/header";
@import "partials/footer";
// Pages
@import "pages/home";
@import "pages/job";
@import "pages/login";
@import "pages/info";
@import "pages/response";