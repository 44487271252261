section.job-header {
    background: #effbff;
    h1 {
        color: #24374e;
        font-size: 32px;
        padding: 0 0 20px;
        @media(max-width: 600px) {
            font-size: 26px;
        }
        .colored {
            color: #00aeef;
        }
    }
    p {
        max-width: 700px;
    }
    .path {
        padding: 0 0 20px;
        color: #24374e;
        a {
            color: inherit;
        }
        span {
            color: #3884db;
        }
    }
}

.jh-table {
    display: table;
    width: 100%;
    &>* {
        display: table-cell;
        vertical-align: top;
    }
}

.jh-info {
    width: auto;
    padding: 80px 40px 40px 35px;
    @media(max-width: 900px) {
        padding: 60px 40px 40px 30px;
    }
    @media(max-width: 800px) {
        padding: 40px 40px 40px 10px;
    }
    @media(max-width: 600px) {
        padding: 30px 10px 20px;
    }
    img {
        display: none;
        @media(max-width: 600px) {
            display: block;
            width: 100%;
            max-width: 320px;
            margin: 5px auto 25px;
        }
    }
}

.jh-image {
    width: 350px;
    padding: 35px 30px 40px 0;
    @media(max-width: 800px) {
        width: 250px;
        padding: 35px 20px 40px 0;
    }
    @media(max-width: 700px) {
        width: 200px;
        padding: 35px 0px 40px 0;
    }
    @media(max-width: 600px) {
        display: none;
    }
    img {
        width: 100%;
        max-width: 350px;
        display: block;
        margin: 0 auto;
    }
}

.job-body {
    padding: 50px 0 40px;
    @media(max-width: 900px) {
        padding: 30px 0;
    }
}

.jb-table {
    display: table;
    width: 100%;
    @media(max-width: 800px) {
        display: block;
    }
    &>* {
        display: table-cell;
        width: 50%;
        vertical-align: top;
        padding: 0 25px 0 35px;
        @media(max-width: 900px) {
            padding: 0 40px 0 10px;
        }
        @media(max-width: 800px) {
            padding: 0 40px 20px 10px;
            display: block;
            width: 100%;
        }
        @media(max-width: 500px) {
            padding: 0 10px 20px 10px;
        }
    }
    h2 {
        font-size: 28px;
        color: #00aeef;
        padding: 0 0 15px;
    }
    ul {
        padding: 0;
    }
    li {
        position: relative;
        list-style: none;
        padding: 0 0 0 30px;
        @media(max-width: 500px) {
            padding: 0 0 0 20px;
        }
        &:before {
            content: "•";
            font-size: 15px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

section.job-form {
    background: #effbff;
    padding: 40px 0 50px;
    @media(max-width: 800px) {
        padding: 30px 0 20px;
    }
    p {
        max-width: 950px;
        padding: 0 35px 20px;
        @media(max-width: 800px) {
            padding: 0 10px 20px;
        }
        @media(max-width: 700px) {
            padding: 0 10px;
        }
    }
    form {
        padding: 0 35px;
        @media(max-width: 800px) {
            padding: 0 10px 20px;
        }
    }
    label {
        display: block;
        color: #00aeef;
        padding: 0 0 7px;
        @media(max-width: 700px) {
            padding: 15px 0 7px;
        }
    }
    input,
    textarea {
        display: block;
        border: 1px solid #deeaf1;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        resize: none;
        font-family: inherit;
        padding: 3px 11px 4px;
        width: 100%;
    }
    input {
        height: 36px;
    }
    textarea {
        height: 120px;
    }
}

.jf-row {
    display: table;
    width: 100%;
    max-width: 950px;
    padding: 0 0 30px;
    position: relative;
    @media(max-width: 700px) {
        display: block;
        padding: 0;
    }
    &>* {
        display: table-cell;
        vertical-align: top;
        position: relative;
        @media(max-width: 700px) {
            display: block;
            width: 100% !important;
            &:after {
                content: '';
                display: block;
                clear: both;
                height: 0;
            }
        }
    }
    .jf-column {
        &:first-of-type {
            width: 33.33%;
            .jf-item {
                padding-right: 20px;
                @media(max-width: 700px) {
                    padding-right: 0;
                    textarea {
                        height: 36px;
                    }
                }
            }
        }
        &:last-of-type {
            width: 66.66%;
        }
    }
    &:first-of-type {
        .jf-column {
            &:last-of-type {
                .jf-item {
                    &:first-of-type {
                        padding-right: 10px;
                        float: left;
                        width: 50%;
                    }
                    &:last-of-type {
                        padding-left: 10px;
                        float: left;
                        width: 50%;
                    }
                    @media(max-width: 500px) {
                        float: none !important;
                        width: 100% !important;
                        padding: 0 !important;
                    }
                }
            }
        }
    }
}
.jf-item-bottom {
    display: table;
    width: 100%;
    position: absolute;
    bottom: 0;
    @media(max-width: 700px) {
        position: relative;
        bottom: unset;
    }
    @media(max-width: 500px) {
        display: block;
    }
    & > * {
        display: table-cell;
        width: 50%;
        vertical-align: top;
        @media(max-width: 500px) {
            display: block;
            width: 100%;
        }
        &:first-of-type {
            padding-right: 10px;
            @media(max-width: 500px) {
                padding-right: 0;
            }
        }
        &:last-of-type {
            padding-left: 10px;
            @media(max-width: 500px) {
                padding-left: 0;
            }
        }
    }
}

.jf-bottom {
    @media(max-width: 700px) {
        padding: 35px 0 0;
    }
    h3 {
        font-family: inherit;
        @media(max-width: 700px) {
            padding: 0 0 10px;
        }
    }
    .colored {
        color: #00aeef;
    }
    input {
        display: none !important;
    }
}

.jf-table {
    display: table;
    width: 100%;
    max-width: 950px;
    padding: 25px 0 0;
    @media(max-width: 700px) {
        padding: 0;
    }
    @media(max-width: 600px) {
        display: block;
    }
    &>* {
        display: table-cell;
        vertical-align: top;
        @media(max-width: 600px) {
            display: block;
        }
    }
}

.jf-upload {
    position: relative;
    width: auto;
    padding: 0 50px 0 170px;
    @media(max-width: 700px){
        padding: 0 20px 0 130px;
    }
    @media(max-width: 600px){
        padding: 0;
    }
    .jf-choose {
        position: absolute;
        left: 0;
        display: block;
        width: 120px;
        color: #fff;
        font-size: 13px;
        background: #939393;
        border-radius: 3px;
        overflow: hidden;
        cursor: pointer;
        text-align: center;
        padding: 9px 0 11px;
        @media(max-width: 600px){
            position: relative;
            left: unset;
        }
        &.lighted {
            background: #ffff00;
            color: #000;
            transition: all 0.2s ease;
        }
        &.normal {
            background: #939393;
            color: #fff;
            transition: all 0.2s ease;
        }
    }
}

.jf-submit {
    width: 144px;
    @media(max-width: 600px) {
        padding-top: 25px;
        width: 100%;
    }
    button {
        display: block;
        border: none;
        cursor: pointer;
        background: #00aeef;
        border-radius: 3px;
        overflow: hidden;
        cursor: pointer;
        text-align: center;
        padding: 9px 0 11px;
        display: block;
        width: 144px;
        color: #fff;
        font-size: 13px;
        float: right;
        @media(max-width: 600px) {
            border-radius: 4px;
            float: none;
            width: 100%;
            padding: 13px 0 15px;
        }
    }
}

.job-list {
    padding: 30px 0 20px;
    .wt-info {
        padding: 50px 30px 80px 0;
        @media(max-width: 1000px) {
            padding: 0;
        }
    }
}

.jf-hidden {
    display: none !important;
}

.popup {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    overflow: auto;
    &.active {
        display: block;
        background: rgba(0,0,0,0.49);
    }
    .popup-content {
        width: 95%;
        max-width: 550px;
        height: 345px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background: #fff;
        border-radius: 10px;
        padding: 50px 0 0;
        @media(max-width: 500px){
            padding: 40px 0;
        }
    }
}
.popup-close {
    display: none;
    content: '';
    width: 28px;
    height: 28px;
    background-image: url(../../images/icons.png);
    background-size: auto 100%;
    background-position-x: -105px;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
    &.active {
        display: block;
    }
}
.popup-status-descr {
    font-size: 15px;
    text-align: center;
    padding: 15px 20px 20px;
    max-width: 380px;
    margin: 0 auto;
}
.popup-status-icon {
    width: 100px;
    height: 100px;
    content: '';
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin: 0 auto;
}
.popup-status-title {
    font-size: 18px;
    text-align: center;
    padding: 30px 0 0;
}
.popup-success {
    display: none;
    &.active {
        display: block;
    }
    .popup-status-title {
        color: #018aff;
    }
    .popup-status-icon {
        background-image: url(../../images/status/success.png);
    }
}
.popup-fail {
    display: none;
    &.active {
        display: block;
    }
    .popup-status-icon {
        background-image: url(../../images/status/fail.png);
    }
}
.popup-waiting {
    display: none;
    &.active {
        display: block;
    }
    .popup-status-title {
        color: #00aeef;
    }
    .popup-status-icon {
        background-image: url(../../images/status/waiting.svg);
    }
}


.form-file {
    position: relative;
    padding: 0 25px 0 15px;
    & + & {
        margin-top: 15px;
    }
    @media(max-width: 600px) {
        padding: 0 25px 0 0;
        margin-top: 15px;
    }
    label {
        display: none !important;
    }
}
.form-file-name-status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.form-file-name {
    font-size: 13px;
    color: #939393;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    padding: 3px 0 6px 20px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -3px;
        width: 16px;
        height: 28px;
        background-image: url(../../images/icons.png);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position-x: -133px;
        display: block;
    }
}
.form-file-status {
    width: auto;
    text-align: right;
    font-size: 12px;
    color: #939393;
    padding: 3px 0 6px 24px;
    &.done {
        color: #04c359;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -3px;
            width: 20px;
            height: 28px;
            background-image: url(../../images/icons.png);
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position-x: -154px;
            display: block;
        }
    }
}
.form-file-progress {
    background: #e8ebf0;
    height: 12px;
    content: '';
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}
.form-file-progress-line {
    background: #04c359;
    height: 12px;
    content: '';
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.05s;
}
.form-file-remove {
    position: absolute;
    cursor: pointer;
    width: 18px;
    height: 18px;
    right: 0;
    content: '';
    background-image: url(../../images/icons.png);
    background-size: auto 100%;
    background-position-x: -67px;
    bottom: -2px;
}