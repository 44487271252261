.info-header {
    background-color: #e9f3fa;
    padding: 40px 0 50px;
    &.info-terms {
        background-image: url(../../images/terms.png);
        background-size: auto 100%;
        background-position: 80% center;
        background-repeat: no-repeat;
        .info-table {
            height: 300px;
        }
        @media(max-width: 1600px) {
            background-position: 85% center;
        }
        @media(max-width: 1300px) {
            background-position: 90% center;
        }
        @media(max-width: 1200px) {
            background-position: 100% center;
            .info-table {
                height: 250px;
            }
        }
        @media(max-width: 1000px) {
            .info-table {
                height: auto;
            }
        }
        @media(max-width: 800px) {
            background-image: unset;
        }
    }
    h1 {
        font-size: 32px;
        color: #0e1821;
        padding: 0 0 15px;
    }
}

.info-table {
    display: table;
    width: 100%;
    padding: 0 0 0 30px;
    @media(max-width: 800px) {
        display: block;
        padding: 0 30px;
    }
    @media(max-width: 600px) {
        padding: 0 10px;
    }
    &>* {
        display: table-cell;
        vertical-align: middle;
    }
    p {
        max-width: 670px;
    }
}

.it-info {
    width: auto;
    @media(max-width: 800px) {
        display: block;
        width: 100%;
    }
    h2 {
        color: #00aeef;
    }
}

.it-image {
    width: 440px;
    @media(max-width: 800px) {
        display: none;
    }
    img {
        width: 100%;
        display: block;
        max-width: 440px;
    }
}

.info-body {
    article {
        padding: 30px 35px 50px;
        @media(max-width: 600px) {
            padding: 30px 10px 40px;
        }
    }
    h2,
    h3,
    h4 {
        margin: 0 0 5px;
        color: #00aeef;
        font-family: inherit;
        font-weight: bold;
    }
    ul {
        padding: 0 0 0 15px;
        li {
            list-style: disc;
        }
    }
    p,
    ul,
    ol {
        margin: 0 0 10px;
        a {
            color: #0e1821;
        }
    }
    table {
        border: 1px solid #0e1821;
        margin: 10px 0 20px;
        width: 100%;
        background: #e9f3fa;
        color: #3885db;
        tr {
            border-bottom: 1px solid #0e1821;
            &:first-of-type {
                font-weight: 700;
                background: #0e1821;
                color: #fff;
            }
            td,
            th {
                border-right: 1px solid #0e1821;
                padding: .5em 1em;
                &:last-of-type {
                    border: none;
                }
            }
            th {
                background: #0e1821;
                text-align: left;
                color: #fff;
            }
        }
    }
}