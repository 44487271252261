footer {
    padding: 0 0 20px;
}

section.footer {
    border-top: 1px solid rgba(226, 226, 226, .5);
    display: table;
    width: 100%;
    padding: 20px 0 10px;
    position: relative;
    &>* {
        display: table-cell;
        vertical-align: top;
    }
    @media(max-width: 600px) {
        display: block;
        &>* {
            display: block
        }
    }
}

.logo-footer {
    width: 150px;
    @media(max-width: 700px) {
        width: 130px;
    }
    @media(max-width: 600px) {
        width: 100%;
    }
    a {
        background-image: url(../../images/logo-footer.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: block;
        width: 139px;
        height: 45px;
        margin-right: 35px;
    }
}

.nav-footer {
    padding: 10px 0 0;
    @media(max-width: 800px) {
        width: 270px;
    }
    @media(max-width: 600px) {
        width: 100%;
        padding: 20px 0 5px;
        flex-wrap: wrap;
        display: flex !important;
        max-width: 340px;
        margin: 0 auto;
    }
    a {
        font-size: 13px;
        margin-right: 35px;
        white-space: nowrap;
        @media(max-width: 700px) {
            margin-right: 20px;
            &:last-of-type {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
        @media(max-width: 600px) {
            margin-right: 0;
            display: block;
            width: 33.33%;
            text-align: center;
            font-size: 12px;
        }
    }
}

.social {
    text-align: right;
    position: relative;
    @media(max-width: 600px) {
        position: absolute;
        top: 20px;
        right: 0;
        padding: 0;
        width: 143px;
    }
    @media(max-width: 400px) {
        width: 133px;
    }
    a {
        display: inline-block;
        width: 28px;
        height: 28px;
        background-image: url(../../images/icons.png);
        background-size: auto 100%;
    }
}

.google {
    display: block;
    width: 110px;
    height: 47px;
    background-image: url(../../images/google.png);
    background-size: 100% auto;
    position: absolute;
    right: 80px;
    top: 0;
    @media(max-width: 600px) {
        left: 0;
        width: 73px;
        height: 33px;
    }
}

.fb-icon {
    background-position-x: 0;
    margin-right: 5px;
    @media(max-width: 400px) {
        margin-right: 2px;
    }
}

.in-icon {
    background-position-x: -28px;
}

.copy {
    font-size: 13px;
    @media(max-width: 600px) {
        font-size: 12px;
        display: block;
        text-align: center;
        padding: 5px 0;
    }
}