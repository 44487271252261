section.bad {
    background: #00aeef;
    color: #fff;
    padding: 150px 0;
    h1 {
        font-size: 32px;
        padding: 0 0 15px;
    }
}

section.success {
    background: #f4f8ff;
    padding: 150px 0;
    h1 {
        font-size: 32px;
        padding: 0 0 15px;
    }
}